<template>
  <div class="formation">
    <Evenement/>
    
  </div>
</template>

<script>
// @ is an alias to /src

import Evenement from '../components/Evenement.vue'

export default {
  name: 'Evenements',
  components: {
    Evenement,
  }
}
</script>
