<template>
    <div>
     
     <hr> <center><h1> {{ h1 }}  </h1></center><hr>
  <section v-if="!spiner" class="box1 container-lg box-center ">
                            <div style="text-align:center" v-if="erreur" class="alert alert-danger" role="alert">
                                  {{ msg }}
                            </div>
                            <div style="text-align:center" v-else-if="msg2!==''" class="alert alert-success" role="alert">
                                  {{ msg2 }}
                            </div>
      <form class="xform">
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault01">Titre</label>
            <input  v-model="titre" type="text" class="form-control" id="validationDefault01" placeholder="Titre" required >
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Type d'activité</label>
            <input v-model="type_activite" type="text" class="form-control" id="validationDefault02" placeholder="Type d'activité"  >
          </div>
          
        </div>
        <div class="form-row">
          
          <div class="col-md mb-2">
            <label for="validationDefault02">Début</label>
            <input v-model="date_debut" type="date" class="form-control" id="validationDefault02" placeholder="Début"  >
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Fin</label>
            <input v-model="date_fin" type="date" class="form-control" id="validationDefault02" placeholder="Fin"  >
          </div>
        </div>
        
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Lieu</label>
            <input v-model="lieu" type="text" class="form-control" id="validationDefault02" placeholder="Lieu"  >
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Nombre de participant</label>
            <input v-model="participant" type="number" class="form-control" id="validationDefault02" placeholder="Nombre..."  >
          </div>

        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Fréquence</label>
            <input v-model="frequence" type="text" class="form-control" id="validationDefault02" placeholder="Fréquence"  >
          </div>
        </div>
        <div class="row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Observation</label>
            <textarea v-model="observation" class="form-control" id="exampleFormControlTextarea1" placeholder="Observation" rows="3"></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Description</label>
            <textarea v-model="description" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
        </div>
        
        
        
        <button @click="envoyer() " class="btn float-right btn-primary" type="submit">Enregistrer</button><br><br>
      </form>
  </section>
          <div v-else class="chargement">
            <div class="spinner-grow text-info"  role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-info"  role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-info"  role="status">
              <span class="sr-only">Loading...</span>
            </div>
              
          </div>
    </div>
    
</template>
<script>
import {tabElemen} from './l_evenement.vue';
import axios from 'axios';
   export default{
       name: 'Evenement',
        data: ()=> {
    return {
      photo:'',titre:'',type_activite:'',lieu:'',frequence:'',observation:'',
      participant:'',date_debut:'',date_fin:'',TabElement:tabElemen,
      mod: false,Etatdeschamps:'',description:'',importation:false,h1:'Nouvelle Evenement',
      msg:'',erreur:false,spiner:false,msg2:'',reponx_code:''
    }
  },
    methods: {
      firstElement:  function(){
            this.selectitem = this.reponx[0];
        },
        ActiverChamps:  function(){
            var champ=document.getElementById('champsD');
            champ.disabled=!champ.disabled;
        },
      envoyer: async function () {
                this.spiner=true
                const EVENEMENT = new FormData();
                if(this.importation && this.TabElement){ EVENEMENT.set('id',this.TabElement.id); }
                EVENEMENT.set('titre',this.titre);
                EVENEMENT.set('description',this.description);
                EVENEMENT.set('type_activite',this.type_activite);
                EVENEMENT.set('date_debut',this.date_debut);
                EVENEMENT.set('lieu',this.lieu);
                EVENEMENT.set('frequence',this.frequence);
                EVENEMENT.set('nbr_participants',this.participant);
                EVENEMENT.set('date_fin',this.date_fin);
                EVENEMENT.set('observation',this.observation);
                axios.post ('https://www.rab-tech.com/dev_mode/js/api/save_event.php',EVENEMENT)
                .then((response) =>{
                console.log(response)
                this.reponx_code = response.data.code;
               } )
               .catch((error)=> {
                 console.log(error);
                 this.msg="Echec de l'enregistrement. Veillez réessayer svp !";
                 this.erreur=true
                 this.spiner=false
               } )
               .finally(()=> {
                if (this.reponx_code=='201') {
                    this.spiner=false;
                    this.msg2="Enregistrement réussit.";
                    this.erreur=false
                    this.titre='';this.description='';this.type_activite='';this.date_debut='';this.lieu='';this.frequence='';
                    this.participant='';this.date_fin='';this.observation='';
                    this.$router.push({path: '/Evennement-liste/0'})

                } else {
                  this.msg="Echec de l'enregistrement. Veillez réessayer svp !";
                  this.erreur=true
                  this.spiner=false
                }
               } )
                
                },
        verifierModification:  function(){
          let lien = document.URL;
          let lien2 = lien.split('/').pop();
            if (this.TabElement && lien2=='1'){
              this.importation=true
              if(this.TabElement.titre){this.h1=this.TabElement.titre}
              if(this.TabElement.titre){this.titre=this.TabElement.titre}
              if(this.TabElement.type_activite){this.type_activite=this.TabElement.type_activite}
              if(this.TabElement.date_debut) {this.date_debut=this.TabElement.date_debut.split(' ')[0] }
              if(this.TabElement.date_fin) {this.date_fin=this.TabElement.date_fin.split(' ')[0]}
              if(this.TabElement.lieu) {this.lieu=this.TabElement.lieu}
              if(this.TabElement.frequence) {this.frequence=this.TabElement.frequence}
              if(this.TabElement.nbr_participants) {this.participant=this.TabElement.nbr_participants}
              if(this.TabElement.observation) {this.observation=this.TabElement.observation}
              if(this.TabElement.description) {this.description=this.TabElement.description}
            }
        }
    },
  created () {
    this.verifierModification()
  }
}
</script>

